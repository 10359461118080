<template>
  <div class="hospitalInfo_container">
    <top style="padding: 0.2rem 0"></top>
    <div class="hospitalInfo_head">新增医生报名账号</div>
    <div class="hospitalInfo_audit_reason" v-if="data.audit_status == 20">
      审核失败原因：{{ data.audit_reason }}
    </div>
    <div class="hospitalInfo_main">
      <div
        class="hospitalInfo_item"
        v-for="(val, index) in dataList"
        :key="index"
      >
        <div style="margin-right: 2vw">
          <span style="color: red">*</span>
          <span style="font-weight: bold" v-if="index + 1 < 10"
            >0{{ index + 1 }}</span
          >
          <span style="font-weight: bold" v-else>{{ index + 1 }}</span>
        </div>
        <div style="flex: 1 0 0">
          <div class="hospitalInfo_item_key">{{ val.label }}</div>
          <div class="hospitalInfo_item_value">
            <el-input
              auto-complete="off"
              v-if="val.contentType == 'input'"
              v-model.trim="data[val.key]"
              :placeholder="`请输入${val.label}`"
              :disabled="val.key == 'hospital_code'"
              @input="changeInput(val)"
              :maxlength="
                val.key == 'phone' || val.key == 'sale_phone' ? 11 : ''
              "
            ></el-input>
            <el-select
              v-if="val.contentType == 'select'"
              v-model="data[val.key]"
              :placeholder="`请选择${val.label}`"
              @change="changeValue(val)"
              :allow-create="val.key == 'hospital' ? false : true"
              :filterable="val.method"
            >
              <el-option
                v-for="(item, index) in getSelectOption(val.key)"
                :key="index"
                :label="item.label || item"
                :value="item.id || item.label || item"
              >
              </el-option>
            </el-select>
            <el-select
              style="margin-top: 10px"
              clearable
              v-model.trim="data.DSM"
              :placeholder="`请选择`"
              v-if="val.key == 'area' && data[val.key]"
              @change="changeValue('DSM')"
            >
              <el-option
                v-for="(item, index) in getSelectOption('DSM')"
                :key="index"
                :label="item.label || item"
                :value="item.id || item.label || item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="hospitalInfo_foot">
      <el-button class="submit" type="primary" @click="doCommit()">
        <span>提交</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import top from "./top.vue";
export default {
  name: "hospitalInfo",
  components: { top },

  data() {
    return {
      config: {
        AreaList: [],
        DSMList: [],
        TaList: ["LC", "PC", "MM", "BC", "Pan tumor", "Other"],
        hospitalList: [],
        departmentList: [],
        citys: [],
        count: [0, 1, 2, 3],
      },
      dataList: [],
      data: {
        area: "",
        DSM: "",
        RMM: "",
        RSM: "",
        sale_name: "",
        sale_phone: "",
        name: "",
        code: "",
        province: "",
        city: "",
        hospital: "",
        hospital_code: "",
        department: "",
        phone: "",
        Ta: "",
        speaker_count: "",
      },
      loading: false,
      dict: {
        phone: {
          label: "医生电话",
          type: "input",
        },
        name: {
          label: "医生姓名",
          type: "input",
        },

        speaker_count: {
          label: "讲者权益",
          type: "select",
        },

        area: {
          label: "区域",
          type: "select",
          method: false,
        },

        sale_name: {
          label: "志愿者姓名",
          type: "input",
        },
        sale_phone: {
          label: "志愿者电话",
          type: "input",
        },
        code: {
          label: "HCP医生编码",
          type: "input",
        },
        province: {
          label: "省份",
          type: "select",
          method: false,
        },
        city: {
          label: "城市",
          type: "select",
          method: false,
        },
        hospital: {
          label: "医院",
          type: "select",
          method: true,
        },
        hospital_code: {
          label: "医院code",
          type: "input",
        },
        department: {
          label: "科室",
          type: "select",
          method: true,
        },
        Ta: {
          label: "主TA",
          type: "select",
        },
      },
      hospitalId: "",
    };
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.$tools.removeTouch();
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = " ";
    this.hospitalId = this.$route.query.id || "";
    if (this.hospitalId) {
      this.initHospitalData();
    } else {
      this.initData();
    }
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    async initHospitalData() {
      let url = this.$tools.getURL(this.$urls.hospital.addDetail, {
        id: this.hospitalId,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          this.initData();
        })
        .catch(() => {});
    },
    initData() {
      let dataList = [];
      for (let key in this.dict) {
        let temp = {
          key,
          label: this.dict[key].label,
          contentType: this.dict[key].type,
          method: this.dict[key].method,
          option: this.dict[key].option || [],
        };
        dataList.push(temp);
      }
      this.dataList = dataList;

      this.getRMM("DSM", 0, false);
      this.getAreaList();
    },
    getAreaList() {
      this.$axios
        .get(this.$urls.hospital.areaList)
        .then((res) => {
          this.config.AreaList = res.data.map((v) => {
            return { label: v.area };
          });
        })
        .catch(() => {});
    },
    //获取AMM RSM DSM
    getRMM(key, e, status = true) {
      if (key && status) {
        this.data.DSM = "";
      }
      let url = this.$tools.getURL(this.$urls.hospital.managerListN, {
        id: e || 0,
        area: this.data.area,
      });
      this.$axios
        .get(url)
        .then((res) => {
          if (key) {
            this.config[`${key}List`] = res.data.map((v) => {
              return { id: v.id, label: v.name_abbreviation };
            });
          } else {
            this.data.RMM = res.data.rmm_data.id;
            this.data.RSM = res.data.rsm_data.id;
          }
        })
        .catch(() => {});
    },
    //获取医院
    getHospital() {
      let url = this.$tools.getURL(this.$urls.hospital.hospitalList, {
        province: this.data.province,
        city: this.data.city,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.hospitalList = res.data.map((v) => {
            return {
              label: v.hospital,
              hospital_code: v.hospital_code,
              department: v.department,
            };
          });
        })
        .catch(() => {});
    },
    getSelectOption(key) {
      switch (key) {
        case "cohosts_count":
        case "guest_count":
        case "speaker_count":
          return this.config.count;
        case "province":
          return this.provinces;
        case "city":
          return this.citys;
        case "area":
          return this.config.AreaList;

        case "DSM":
          return this.config.DSMList;
        case "hospital":
          return this.config.hospitalList;
        case "department":
          return this.config.departmentList;
        case "Ta":
          return this.config.TaList;
      }
    },
    changeInput(item) {
      switch (item.key) {
        case "phone":
        case "sale_phone":
          this.data[item.key] = this.data[item.key].replace(/\D/g, "");
          break;
      }
    },
    changeValue(item) {
      switch (item?.key || item) {
        case "area":
          this.getRMM("DSM");
          break;
        case "DSM":
          this.getRMM("", this.data[item?.key || item]);
          break;
        case "province":
          this.changeProvince();
          break;
        case "city":
          this.changeCity();
          break;
        case "hospital":
          this.changeHospital();
          break;
      }
    },
    changeHospital() {
      this.config.hospitalList.filter((v) => {
        if (v.label == this.data.hospital) {
          this.data.hospital_code = v.hospital_code;
          this.config.departmentList = v.department;
        }
      });
      this.data.department = "";
    },
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
      this.data.hospital_code = "";
      this.data.department = "";
    },
    changeCity() {
      //筛选医院
      this.data.hospital = "";
      this.data.hospital_code = "";
      this.data.department = "";
      this.getHospital();
    },
    checkData() {
      for (let index = 0; index < this.dataList.length; index++) {
        const element = this.dataList[index];
        switch (element.key) {
          // case "RMM":
          // case "RSM":
          case "area":
          case "DSM":
          case "province":
          case "city":
          case "department":
          case "cohosts_count":
          case "guest_count":
          case "speaker_count":
            if (this.data[element.key] === "") {
              this.$tips.error({ text: `请选择${element.label}` });
              return false;
            }
            break;
          case "sale_name":
          case "name":
          case "Ta":
            if (this.data[element.key] === "") {
              this.$tips.error({ text: `请输入${element.label}` });
              return false;
            }
            break;
          case "code": //12位长度限制
            if (
              !this.data[element.key] &&
              !/^\d{2,12}$/.test(this.data[element.key])
            )
              return this.$tips.error({ text: `请输入2-12位HCP医生编码` });

            break;
          case "sale_phone":
          case "phone":
            if (!/^[1]\d{10}$/.test(this.data[element.key]))
              return this.$tips.error({ text: `请输入正确的手机号` });
            break;
        }
      }
      // if (
      //   this.data.cohosts_count +
      //     this.data.speaker_count +
      //     this.data.guest_count >
      //   3
      // ) {
      //   this.$tips.error({ text: "本期活动每位专家最多参与3次权益，请调整" });
      //   return false;
      // }
      return true;
    },

    //提交医生信息
    doCommit() {
      if (!this.checkData() || this.loading) return;
      if (this.hospitalId) {
        this.doUpdate();
      } else {
        this.doCreat();
      }
    },
    doCreat() {
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .post(this.$urls.hospital.addApply, this.data)
        .then((res) => {
          console.log("res :>> ", res);
          this.loading = false;
          this.$tips.success({ text: "提交成功" });
          setTimeout(() => {
            window.location.href = `${this.$config.report}/manage/#/navigation`;
          }, 500);
          currLoading.close();
        })
        .catch(() => {
          this.loading = false;
          currLoading.close();
        });
    },
    doUpdate() {
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.hospital.patchApply, this.data)
        .then((res) => {
          console.log("res :>> ", res);
          this.loading = false;
          this.$tips.success({ text: "提交成功" });
          setTimeout(() => {
            window.location.href = `${this.$config.report}/manage/#/navigation`;
          }, 500);
          currLoading.close();
        })
        .catch(() => {
          this.loading = false;
          currLoading.close();
        });
    },
  },
};
</script>
<style>
.hospitalInfo_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  background: #f3f5f7;
  padding: 16px;
}
.hospitalInfo_head {
  background: #fff;
  border-radius: 8px;
  min-height: 80px;
  font-size: 24px;
  font-weight: bold;
  padding-left: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.hospitalInfo_item {
  background: #fff;
  border-radius: 8px;
  min-height: 80px;
  font-size: 18px;
  padding: 24px;
  display: flex;
  margin-bottom: 16px;
}
.hospitalInfo_item_key {
  margin-bottom: 8px;
}
.hospitalInfo_container .el-select {
  width: 100%;
}
.hospitalInfo_foot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hospitalInfo_foot .submit {
  width: 80%;
}
.hospitalInfo_audit_reason {
  background: #fff;
  color: red;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  padding-left: 24px;
  word-break: break-all;
}
.hospitalInfo_item_value {
  position: relative;
}
.hospitalInfo_container .el-select__wrapper,
.hospitalInfo_container .el-input__wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #ccc !important;
}
</style>